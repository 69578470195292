import './src/styles/global.scss'
import { GatsbyBrowser } from 'gatsby'
import React from 'react'
import { WithPageQueryParamsProxyIntoLinks } from './src/components/Analytics/WithPageQueryParamsProxyIntoLinks'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
    element,
}) => {
    return (
        <WithPageQueryParamsProxyIntoLinks>
            {element}
        </WithPageQueryParamsProxyIntoLinks>
    )
}
