/**
 * This HOC component - proxy page query params into /id/sign links on page
 * window.proxyQueryParamsPages: string[] controls list of pages with this behaviour
 */
import React, { useEffect } from 'react'

type WindowWithProxyQueryParamsPagesProperty = typeof window & {
    proxyQueryParamsPages?: string[]
}

function handler(event: React.MouseEvent<HTMLDivElement>) {
    if (typeof window === 'undefined') return

    if (
        !(window as WindowWithProxyQueryParamsPagesProperty)
            ?.proxyQueryParamsPages ||
        !(
            window as WindowWithProxyQueryParamsPagesProperty
        )?.proxyQueryParamsPages?.includes(window.location.pathname) ||
        !(event.target instanceof HTMLAnchorElement)
    ) {
        return
    }

    const clickedLink: HTMLAnchorElement = event.target as HTMLAnchorElement

    if (!clickedLink.href.includes('/id/sign')) {
        return
    }

    const linkUrl = new URL(clickedLink.href)
    const pageQueryParams = new URL(window.location.href).searchParams.entries()

    for (const [param, value] of pageQueryParams) {
        linkUrl.searchParams.set(param, value)
    }

    clickedLink.href = linkUrl.toString()
}

export function WithPageQueryParamsProxyIntoLinks({
    children,
}: {
    children: any
}) {
    useEffect(() => {
        document.addEventListener('click', handler as unknown as EventListener)
        return () =>
            document.removeEventListener(
                'click',
                handler as unknown as EventListener
            )
    }, [])

    return children
}
